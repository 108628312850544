<template>
  <div class="attention baseConsoleTabPage">
    <div class="tabBlock">
      <el-tabs class="tabwrap" v-model="status" @tab-click="handleTabClick">
        <el-tab-pane :label="$t('all')" name="1"></el-tab-pane>
        <el-tab-pane :label="$t('ToParticipate')" name="2"></el-tab-pane>
        <el-tab-pane :label="$t('pending')" name="3"></el-tab-pane>
        <el-tab-pane :label="$t('pengdingComment ')" name="4"></el-tab-pane>
      </el-tabs>
      <div class="selectWrap" v-if="status === '1' && showSelect">
        <el-select
          @change="selectValue"
          size="small"
          v-model="selecttalk"
          :placeholder="$t('pleaseSelect')"
        >
          <el-option
            v-for="item in selectoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="tableBlock">
      <el-table
        ref="tableImmedia"
        v-if="status === '1' && selecttalk === 2"
        :data="immediateData"
        style="width: 100%"
        height="100"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column prop="date" :label="$t('talkTime')" width="240">
          <template slot-scope="scope">
            <div>
              {{ scope.row.start_time | secondFormat("LLL") }} -
              {{ scope.row.end_time | secondFormat("T") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="user" :label="$t('talkPerson')" min-width="200">
          <template slot-scope="scope">
            <div
              class="user"
              @click="getCard(scope.row)"
            >
              <div>
                <el-image
                  slot="reference"
                  :src="
                    scope.row.chat_user_avatar
                      ? scope.row.chat_user_avatar
                      : DEFAULT_AVATAR
                  "
                  class="userImg"
                  fit="scale-down"
                ></el-image>
              </div>
              <div class="userInfo textOverflow">
                <div
                  class="textOverflow name"
                  :title="
                    scope.row.chat_user_name_en
                      | priorFormat(scope.row.chat_user_name_zh, LOCALE)
                  "
                >
                  {{
                    scope.row.chat_user_name_en
                      | priorFormat(scope.row.chat_user_name_zh, LOCALE)
                  }}
                </div>
                <div
                  class="textOverflow"
                  :title="
                    scope.row.chat_user_job_title_en
                      | priorFormat(scope.row.chat_user_job_title_zh, LOCALE)
                  "
                >
                  {{
                    scope.row.chat_user_job_title_en
                      | priorFormat(scope.row.chat_user_job_title_zh, LOCALE)
                  }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column> 
        <el-table-column
          prop="name"
          :label="$t('WCompanyName')"
          min-width="200"
        >
          <template slot-scope="scope">
            <div
              @click="
                goToBoothById(scope.row.company_booth_id, scope.row.company_id)
              "
              :class="[scope.row.company_booth_id ? 'clickValue' : '']"
              class="textOverflow"
              :title="
                scope.row.company_name_en
                  | priorFormat(scope.row.company_name_zh, LOCALE)
              "
            >
              {{
                scope.row.company_name_en
                  | priorFormat(scope.row.company_name_zh, LOCALE)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('AppointmentType')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.chat_type == 2">
              {{ $t("receive") }}
            </span>
            <span v-if="scope.row.chat_type == 1">
              {{ $t("sendInvitation") }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        ref="tabledata"
        v-else
        :data="tableData"
        style="width: 100%"
        height="100"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column prop="date" :label="$t('talkTime')" width="240">
          <template slot-scope="scope">
            <div>
              {{ scope.row.start_time | secondFormat("LLL") }} -
              {{ scope.row.end_time | secondFormat("T") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="user" :label="$t('talkPerson')" min-width="200">
          <div slot-scope="scope" v-if="PJSource!=7 &&PJSource !==21 && PJSource !==16&&PJSource !==12&& PJSource!==18&&PJSource!==27&&PJSource !==29">
            <el-popover
              placement="top-start"
              width="416"
              trigger="click"
              popper-class="mymarkanappointment"
            >
              <userMsg :item="scope.row.itemMsg" />
              <div class="user" v-if="scope.row.type == 1" slot="reference">
                <div>
                  <el-image
                    :src="
                      scope.row.send_user_avatar
                        ? scope.row.send_user_avatar
                        : DEFAULT_AVATAR
                    "
                    class="userImg"
                    fit="scale-down"
                  ></el-image>
                </div>
                <div class="userInfo textOverflow">
                  <div
                    class="textOverflow name"
                    :title="
                      scope.row.send_user_name_en
                        | priorFormat(scope.row.send_user_name_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.send_user_name_en
                        | priorFormat(scope.row.send_user_name_zh, LOCALE)
                    }}
                  </div>
                  <div
                    class="textOverflow"
                    :title="
                      scope.row.send_user_job_title_en
                        | priorFormat(scope.row.send_user_job_title_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.send_user_job_title_en
                        | priorFormat(scope.row.send_user_job_title_zh, LOCALE)
                    }}
                  </div>
                </div>
              </div>
            </el-popover>
            <el-popover
              placement="top-start"
              width="416"
              trigger="click"
              popper-class="mymarkanappointment"
            >
              <userMsg :item="scope.row.itemMsg" />
              <div class="user" v-if="scope.row.type == 2" slot="reference">
                <div>
                  <el-image
                    :src="
                      scope.row.in_user_avatar
                        ? scope.row.in_user_avatar
                        : DEFAULT_AVATAR
                    "
                    class="userImg"
                    fit="scale-down"
                  ></el-image>
                </div>
                <div class="userInfo textOverflow">
                  <div
                    class="textOverflow name"
                    :title="
                      scope.row.in_user_name_en
                        | priorFormat(scope.row.in_user_name_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.in_user_name_en
                        | priorFormat(scope.row.in_user_name_zh, LOCALE)
                    }}
                  </div>
                  <div
                    class="textOverflow"
                    :title="
                      scope.row.in_user_job_title_en
                        | priorFormat(scope.row.in_user_job_title_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.in_user_job_title_en
                        | priorFormat(scope.row.in_user_job_title_zh, LOCALE)
                    }}
                  </div>
                </div>
              </div>
            </el-popover>
          </div>
          <div  slot-scope="scope" v-else>
              <div class="user" v-if="scope.row.type == 1" >
                <div  @click="getCard(scope.row)">
                  <el-image
                    :src="
                      scope.row.send_user_avatar
                        ? scope.row.send_user_avatar
                        : DEFAULT_AVATAR
                    "
                    class="userImg"
                    fit="scale-down"
                  ></el-image>
                </div>
                <div class="userInfo textOverflow">
                  <div
                    class="textOverflow name"
                    :title="
                      scope.row.send_user_name_en
                        | priorFormat(scope.row.send_user_name_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.send_user_name_en
                        | priorFormat(scope.row.send_user_name_zh, LOCALE)
                    }}
                  </div>
                  <div
                    class="textOverflow"
                    :title="
                      scope.row.send_user_job_title_en
                        | priorFormat(scope.row.send_user_job_title_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.send_user_job_title_en
                        | priorFormat(scope.row.send_user_job_title_zh, LOCALE)
                    }}
                  </div>
                </div>
              </div>
              <div class="user" v-if="scope.row.type == 2" >
                <div  @click="getCard(scope.row)">
                  <el-image
                    :src="
                      scope.row.in_user_avatar
                        ? scope.row.in_user_avatar
                        : DEFAULT_AVATAR
                    "
                    class="userImg"
                    fit="scale-down"
                  ></el-image>
                </div>
                <div class="userInfo textOverflow">
                  <div
                    class="textOverflow name"
                    :title="
                      scope.row.in_user_name_en
                        | priorFormat(scope.row.in_user_name_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.in_user_name_en
                        | priorFormat(scope.row.in_user_name_zh, LOCALE)
                    }}
                  </div>
                  <div
                    class="textOverflow"
                    :title="
                      scope.row.in_user_job_title_en
                        | priorFormat(scope.row.in_user_job_title_zh, LOCALE)
                    "
                  >
                    {{
                      scope.row.in_user_job_title_en
                        | priorFormat(scope.row.in_user_job_title_zh, LOCALE)
                    }}
                  </div>
                </div>
              </div>
          </div>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('WCompanyName')"
          min-width="200"
        >
          <template slot-scope="scope">
            <span></span>
            <div
              @click="
                goToBoothById(
                  scope.row.send_booth_id,
                  scope.row.send_company_id
                )
              "
              :class="[scope.row.send_booth_id ? 'clickValue' : '']"
              v-if="scope.row.type == 1"
              class="textOverflow"
              :title="
                scope.row.send_company_name_en
                  | priorFormat(scope.row.send_company_name_zh, LOCALE)
              "
            >
              {{
                scope.row.send_company_name_en
                  | priorFormat(scope.row.send_company_name_zh, LOCALE)
              }}
            </div>
            <div
              @click="
                goToBoothById(scope.row.in_booth_id, scope.row.in_company_id)
              "
              :class="[scope.row.in_booth_id ? 'clickValue' : '']"
              v-if="scope.row.type == 2"
              class="textOverflow"
              :title="
                scope.row.in_company_name_en
                  | priorFormat(scope.row.in_company_name_zh, LOCALE)
              "
            >
              {{
                scope.row.in_company_name_en
                  | priorFormat(scope.row.in_company_name_zh, LOCALE)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('status')" width="150">
          <template slot-scope="scope">
            <span></span>
            <div v-if="scope.row.status == 2 && scope.row.isEnd != 3">
              {{ $t("ToParticipate") }}
            </div>
            <div v-if="scope.row.status == 1 && scope.row.isEnd != 3">
              {{ $t("pending") }}
            </div>
            <div v-if="scope.row.status == 2 && scope.row.isEnd == 3">
              {{ $t("havemeetingEnd") }}
            </div>
            <div v-if="scope.row.status == 3">{{ $t("rejected") }}</div>
            <div v-if="scope.row.status == 4 || scope.row.status == 5">
              {{ $t("cancelled") }}
            </div>
            <div v-if="scope.row.status == 1 && scope.row.isEnd == 3">
              {{ $t("expired") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('AppointmentType')" width="160">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.in_user_id == USER_ID
                  ? $t("receive")
                  : $t("sendInvitation")
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('notes')" width="200">
          <template slot-scope="scope">
            <div
              class="textOverflow"
              :title="scope.row.send_postscript | textFormat"
            >
              <!-- :title="scope.row.in_user_id == USER_ID ? scope.row.send_postscript : scope.row.send_postscript | textFormat" -->
              <!-- {{ scope.row.in_user_id == USER_ID ? scope.row.send_postscript : scope.row.send_postscript | textFormat }} -->
              {{ scope.row.send_postscript | textFormat }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('handle')"
          width="190"
          fixed="right"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status != 3">
              <!--<el-popconfirm
                v-if="
                  scope.row.status == 2 &&
                  scope.row.isEnd != 3 &&
                  scope.row.type == 2
                "
                :title="$t('confirmCancel')"
                placement="top"
                :confirmButtonText="$t('confirm')"
                :cancelButtonText="$t('cancel')"
                @onConfirm="handle(scope.row, 4)"
                :hideIcon="true"
              >
                <el-button slot="reference" type="text" class="marginL10">{{
                  $t("clearAppointment")
                }}</el-button>
              </el-popconfirm>
              <el-popconfirm
                v-if="
                  scope.row.status == 2 &&
                  scope.row.isEnd != 3 &&
                  scope.row.type == 1
                "
                placement="top"
                :confirmButtonText="$t('confirm')"
                :cancelButtonText="$t('cancel')"
                @onConfirm="handle(scope.row, 5)"
                :hideIcon="true"
              >
                <el-button slot="reference" type="text" class="marginL10">{{
                  $t("clearAppointment")
                }}</el-button>
              </el-popconfirm> -->
              <el-button
                v-if="
                  scope.row.status == 2 &&
                  scope.row.isEnd != 3 &&
                  scope.row.type == 2
                "
                type="text"
                class="marginL10"
                @click="handleCencel(scope.row, 4)"
                >{{ $t("clearAppointment") }}</el-button
              >
              <el-button
                v-if="
                  scope.row.status == 2 &&
                  scope.row.isEnd != 3 &&
                  scope.row.type == 1
                "
                type="text"
                class="marginL10"
                @click="handleCencel(scope.row, 5)"
                >{{ $t("clearAppointment") }}</el-button
              >
              <el-button
                v-if="
                  scope.row.status == 2 &&
                  scope.row.isEnd != 3 &&
                  PJname == 'overseasMeeting'
                "
                type="text"
                class="marginL10"
                @click="inviteGo(scope.row)"
                >{{ $t("invite") }}
              </el-button>
              <el-button
                v-if="scope.row.status == 2 && scope.row.isEnd != 3"
                type="text"
                class="marginL10"
                @click="handleGo(scope.row)"
                >{{ $t("meetingEnter") }}
              </el-button>
              <!-- <el-image
                  v-if="scope.row.status == 2 && scope.row.isEnd != 3"
                  :src="idcodeImg"
                  style="width: 20px; margin-left: 10px;vertical-align: middle;cursor: not-allowed;"
                  class="cursor"
                  @click="isIdCodeDialog(scope.row)"
              ></el-image> -->
              <!-- <el-popconfirm
                v-if="
                  scope.row.status == 1 &&
                  scope.row.isEnd != 3 &&
                  scope.row.type == 1
                "
                placement="top"
                :confirmButtonText="$t('confirm')"
                :cancelButtonText="$t('cancel')"
                @onConfirm="handleAccept(scope.row, 2)"
                :hideIcon="true"
              >
                <el-button slot="reference" type="text" class="marginL10">
                  {{ $t("Accepted") }}
                </el-button>
              </el-popconfirm> -->
              <el-button
                v-if="
                  scope.row.status == 1 &&
                  scope.row.isEnd != 3 &&
                  scope.row.type == 1
                "
                type="text"
                class="marginL10"
                @click="handleAccept(scope.row, 2)"
              >
                {{ $t("Accepted") }}
              </el-button>
              <!-- <el-popconfirm
                v-if="
                  scope.row.status == 1 &&
                  scope.row.isEnd != 3 &&
                  scope.row.type == 1
                "
                placement="top"
                :confirmButtonText="$t('confirm')"
                :cancelButtonText="$t('cancel')"
                @onConfirm="handle(scope.row, 3)"
                :hideIcon="true"
              >
                <el-button slot="reference" type="text" class="marginL10">{{
                  $t("refuse")
                }}</el-button>
                 
              </el-popconfirm> -->
              <el-button
                v-if="
                  scope.row.status == 1 &&
                  scope.row.isEnd != 3 &&
                  scope.row.type == 1
                "
                type="text"
                class="marginL10"
                @click="handle(scope.row, 3)"
              >
                {{ $t("refuse") }}
              </el-button>
              <el-button
                v-if="
                  (scope.row.status == 2 &&
                    scope.row.isEnd == 3 &&
                    scope.row.type == 1 &&
                    scope.row.in_evaluat == 0) ||
                  (scope.row.isEnd == 3 &&
                    scope.row.status == 2 &&
                    scope.row.type == 2 &&
                    scope.row.send_evaluat == 0)
                "
                type="text"
                class="marginL10"
                @click="handleAddEvaluate(scope.row)"
                >{{ $t("appraise") }}
              </el-button>
              <el-button
                v-if="
                  (scope.row.status == 2 &&
                    scope.row.isEnd == 3 &&
                    scope.row.type == 2 &&
                    scope.row.send_evaluat == 1) ||
                  (scope.row.isEnd == 3 &&
                    scope.row.status == 2 &&
                    scope.row.type == 1 &&
                    scope.row.in_evaluat == 1)
                "
                type="text"
                class="marginL10"
                @click="handleViewEvaluate(scope.row)"
              >
                {{ $t("viewEvaluation") }}
              </el-button>
              <!-- <el-popconfirm
                v-if="
                  scope.row.status == 1 &&
                  scope.row.isEnd != 3 &&
                  scope.row.type == 2
                "
                placement="top"
                :confirmButtonText="$t('confirm')"
                :cancelButtonText="$t('cancel')"
                @onConfirm="handle(scope.row, 4)"
                :hideIcon="true"
              >
                <el-button slot="reference" type="text" class="marginL10">{{
                  $t("clearAppointment")
                }}</el-button>
              </el-popconfirm> -->
              <el-button
                v-if="
                  scope.row.status == 1 &&
                  scope.row.isEnd != 3 &&
                  scope.row.type == 2
                "
                type="text"
                class="marginL10"
                @click="handleCencel(scope.row, 4)"
                >{{ $t("clearAppointment") }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        layout="total, prev, pager, next"
        :total="total"
        :page-size="limit"
        :current-page.sync="start"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-if="evaluateDialog"
      :title="evaluateType == 'add' ? $t('AddComment') : $t('viewEvaluation')"
      :visible.sync="evaluateDialog"
      width="800px"
      :destroy-on-close="true"
    >
      <evaluate
        :type="evaluateType"
        :item="item"
        @handleCancelEvaluate="handleCancelEvaluate"
        @handleSaveEvaluate="handleSaveEvaluate"
      />
    </el-dialog>
    <idCode
      ref="idCode"
      :channel_id="idCode.live_id"
      :chat_type="idCode.chat_type"
    ></idCode>
    <cardDialog :followedId="currentId"></cardDialog>
    <el-dialog
      v-if="chat1v1Show"
      :title="$t('meeting1v1')"
      :visible.sync="chat1v1Show"
      width="600px"
    >
      <userChat
        :miniInfo="chatInfo"
        ref="userChat"
        :chatType="'d_order'"
        :isChatHide="true"
      ></userChat>
    </el-dialog>
    <inviteOther ref="inviteOther"></inviteOther>
    <cardIsDialog></cardIsDialog>
  </div>
</template>

<script>
import idCode from "~/baseComponents/idCode";
import evaluate from "~bac/components/conference/myReserved/evaluate";
import inviteOther from "~bac/components/conference/myReserved/inviteOther";
import { getAilaDomainName } from "~/baseUtils";
import userMsg from "~/baseComponents/card/userMsg";
import { priorFormat } from "@/basePlugins/filters";
import cardDialog from "@/baseComponents/card/cardDialog";
import userChat from "~/baseComponents/chat/userChat";
import { handleToVideoChatPage } from "~/baseUtils";
import cardIsDialog from "@/baseComponents/card/cardIsDialog";
export default {
  name: "myReserved",
  components: { evaluate, idCode, userMsg, cardDialog, userChat, inviteOther,cardIsDialog },
  data() {
    return {
      confirmLoading: false,
      tableData: [],
      immediateData: [],
      total: 0,
      start: 1,
      status: "1",
      limit: 10,
      loading: false,
      evaluateDialog: false,
      chat1v1Show: false,
      chatInfo: {},
      evaluateType: "add",
      item: {},
      PJname: "",
      idCode: {
        live_id: null,
        chat_type: null,
      },
      idcodeImg: require("~scr/assets/img/idcode1.png"),
      selectoptions: [
        {
          label: this.$t("Make_Appointment"),
          value: 1,
        },
        {
          label: this.$t("Instant_negotiation"),
          value: 2,
        },
      ],
      selecttalk: 1,
      currentId: 0,
    };
  },
  mounted() {
    this.PJname = process.env.VUE_APP_PROJECT_NAME;
    if (status == 1) {
      if (selecttalk === 1) {
        this.getDataList();
      } else {
        this.getImmediateList();
      }
    } else {
      this.getDataList();
    }
  },
  methods: {
    async getDataList() {
      this.tableData = [];
      this.loading = true;
      let params = {
        start: this.start - 1,
        limit: this.limit,
        user_id: this.USER_ID,
        source: this.PJSource,
      };
      if (this.status == 1) {
        params.status = "";
      }
      if (this.status == 2) {
        params.status = 2;
      }
      if (this.status == 3) {
        params.status = 1;
      }
      if (this.status == 4) {
        params.in_evaluat = 0;
        params.send_evaluat = 0;
      }
      try {
        let result = await this.$store.dispatch(
          "baseConsole/getmyReservedList",
          params
        );
        if (result.success) {
          this.loading = false;
          let time = new Date();
          let nowTime = moment(time).unix();
          result.data.forEach((item) => {
            if (this.USER_ID == item.in_user_id) {
              // if (52175 == item.in_user_id) {
              item.type = 1; //接受邀请方
            } else {
              item.type = 2; //发送邀请方
            }
            if (nowTime < item.start_time) {
              item.isEnd = "1";
            }
            if (nowTime > item.start_time && nowTime < item.end_time) {
              item.isEnd = "2";
            }
            if (nowTime > item.end_time) {
              item.isEnd = "3";
            }
          });

          this.tableData = result.data;
          this.tableData.forEach((item, index) => {
            if (item.type === 1) {
              item.itemMsg = {
                name: priorFormat(
                  item.send_user_name_en,
                  item.send_user_name_zh,
                  this.LOCALE
                ),
                company: priorFormat(
                  item.send_company_name_en,
                  item.send_company_name_zh,
                  this.LOCALE
                ),
                address: priorFormat(
                  item.send_address_en,
                  item.send_address_zh,
                  this.LOCALE
                ),
                job: priorFormat(
                  item.send_user_job_title_en,
                  item.send_user_job_title_zh,
                  this.LOCALE
                ),
                country: item.send_country_id,
                city: item.send_city_id,
                mobile: item.send_user_mobile,
                email: item.send_user_email,
                industry: item.send_industry,
                region_en: item.send_region_en,
                region_zh: item.send_region_zh,
                avatar: item.send_user_avatar,
              };
            } else {
              item.itemMsg = {
                name: priorFormat(
                  item.in_user_name_en,
                  item.in_user_name_zh,
                  this.LOCALE
                ),
                company: priorFormat(
                  item.in_company_name_en,
                  item.in_company_name_zh,
                  this.LOCALE
                ),
                address: priorFormat(
                  item.in_address_en,
                  item.in_address_zh,
                  this.LOCALE
                ),
                job: priorFormat(
                  item.in_user_job_title_en,
                  item.in_user_job_title_zh,
                  this.LOCALE
                ),
                country: item.in_country_id,
                city: item.in_city_id,
                mobile: item.in_user_mobile,
                email: item.in_user_email,
                industry: item.in_industry,
                region_en: item.in_region_en,
                region_zh: item.in_region_zh,
                avatar: item.in_user_avatar,
              };
            }
          });
          this.total = result.count;
        }
      } catch (e) {}
    },
    selectValue(e) {
      this.start = 1;
      this.total = 0;
      if (e === 1) {
        this.getDataList();
        this.$nextTick(() => {
          this.$refs["tabledata"].doLayout();
        });
      } else {
        this.getImmediateList();
        this.$nextTick(() => {
          this.$refs["tableImmedia"].doLayout();
        });
      }
    },
    async getImmediateList() {
      this.immediateData = [];
      this.loading = true;
      let params = {};
      params.start = this.start - 1;
      params.limit = this.limit;
      params.meeting_id = this.MEETING_ID;
      params.user_id = this.USER_INFO.id;
      try {
        let res = await this.$store.dispatch(
          "baseConsole/immediateList",
          params
        );
        if (res.success) {
          this.loading = false;
          this.immediateData = res.data;
          this.total = res.total;
        }
      } catch (error) {}
    },
    handleTabClick(e) {
      this.start = 1;
      this.total = 0;
      if (e.name === "1") {
        if (this.selecttalk === 1) {
          this.getDataList();
          this.$nextTick(() => {
            this.$refs["tabledata"].doLayout();
          });
        } else {
          this.getImmediateList();
          this.$nextTick(() => {
            this.$refs["tableImmedia"].doLayout();
          });
        }
      } else {
        this.getDataList();
        this.$nextTick(() => {
          this.$refs["tabledata"].doLayout();
        });
      }
    },
    handlePageChange(page) {
      this.start = page;
      if (this.status == "1") {
        if (this.selecttalk === 1) {
          this.getDataList();
        } else {
          this.getImmediateList();
        }
      } else {
        this.getDataList();
      }
    },
    async handleAccept(row, num) {
      let isStay = await this.isStay(row);
      if (!isStay) return;
      let query = {
        start_time: row.start_time,
        end_time: row.end_time,
        user_id: this.USER_ID,
        source: this.PJSource,
        daily_source: 1,
        related_id: this.MEETING_ID,
      };
      let checkStatus = await this.$store.dispatch(
        "baseConsole/inUserStatusCheck",
        query
      );
      if (checkStatus.success) {
        this.handle(row, num);
      } else {
        const h = this.$createElement;
        const newDatas = [];
        newDatas.push(h("span", null, this.$t("timehavemeeting")));
        // newDatas.push(h("div", { style: "color: #1890FF; height: 10px" }, ""));
        // newDatas.push(
        //   h(
        //     "div",
        //     { style: "color: #666666; font-size: 12px" },
        //     this.$t("noteaftermeetingperiod")
        //   )
        // );
        newDatas.push(h("div", { style: "color: #1890FF; height: 20px" }, ""));
        this.$confirm("", {
          message: h("div", null, newDatas),
          confirmButtonText: this.$t("Accepted"),
          cancelButtonText: this.$t("cancel"),
          showClose: false,
          customClass: "acceptConfirm",
        })
          .then(() => {
            this.handle(row, num);
          })
          .catch(() => {
            return;
          });
      }
    },
    //  点击取消
    async handle(row, num) {
      // 等于3的时候。是点击了拒绝， 接受已经在handleAccept函数调用过
      if (num == 3) {
        let isStay = await this.isStay(row);
        if (!isStay) return;
      }
      let params = {};
      params.status = num;
      params.daily_one2one_chat_id = row.daily_one2one_chat_id;
      params.user_id = this.USER_ID;
      // params.user_id = 52175
      params.source = this.PJSource;
      let result = await this.$store.dispatch(
        "baseConsole/expoDailyInviteOperate",
        params
      );
      if (result.success) {
        this.$osTip();
        this.getDataList();
      }
    },
    // 点击取消预约
    handleCencel(row, num) {
      this.$confirm(this.$t("confirmCancel"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        closeOnClickModal: false,
        type: "warning",
      })
        .then(async () => {
          this.handle(row, num);
        })
        .catch(() => {});
    },
    //  点击进入
    handleGo(row) {
      if (this.PJSource === 10 || this.PJSource === 11) {
        this.goToVchatPagetips(row.start_time, row.end_time, false, false, {
          todo: () => {
            this.chat1v1Show = true;
            this.$nextTick((e) => {
              this.chatInfo = {
                user_id: this.USER_INFO.id,
                live_id: row.live_id,
              };
            });
          },
        });
      } else if (this.PJSource === 17) {
        this.goToMultiPage(
          row.live_id,
          this.USER_INFO.id,
          row.start_time,
          row.end_time
        );
      } else {
        if (
          moment().isBefore(
            this.$moment(row.start_time * 1000).add("-10", "m"),
            "seconds"
          )
        ) {
          this.$message.warning(this.$t("enterMeetingBeforeTen"));
        } else {
          handleToVideoChatPage(
            row.live_id,
            this.USER_INFO.id,
            "d_order",
            true
          );
        }
      }
    },
    // 添加评价
    handleAddEvaluate(row) {
      this.item = Object.assign({}, row);
      this.evaluateDialog = true;
      this.evaluateType = "add";
    },
    //  查看评价
    handleViewEvaluate(row) {
      this.item = Object.assign({}, row);
      this.evaluateDialog = true;
      this.evaluateType = "view";
    },
    handleCancelEvaluate() {
      this.evaluateDialog = false;
    },
    handleSaveEvaluate() {
      this.evaluateDialog = false;
      this.$osTip();
      this.getDataList();
    },
    // 二维码
    isIdCodeDialog(item) {
      return;
      this.idCode.live_id = item.live_id;
      this.idCode.chat_type = 1;
      this.$nextTick(() => {
        this.$refs.idCode.isIdCodeDialog();
      });
    },
    //  接受和拒绝，判断是否在页面长时间停留
    async isStay(item) {
      //  判断当前时间是否在已过期
      let now_time = moment(new Date()).unix();
      if (item.end_time < now_time) {
        this.$message.warning(this.$t("currenthasexpired"));
        this.getDataList();
        return false;
      }
      let params = {
        daily_one2one_chat_id: item.daily_one2one_chat_id,
      };
      let res = await this.$store.dispatch("baseConsole/isCheckCancel", params);
      if (res.success && res.data.status == 4) {
        this.$message.warning(this.$t("currenthasbeencancelled"));
        this.getDataList();
        return false;
      }
      return true;
    },
    //点击公司名跳转展台
    goToBoothById(booth_id, company_id) {
      if (booth_id) {
        this.$router.push({
          path: "/boothDetail",
          query: {
            company_id: company_id,
            booth_id: booth_id,
          },
        });
      }
    },
    getCard(item) {
      let row={}
        if(this.USER_INFO.id==item.send_user_id){
            row  = {
              user_id:item.in_user_id,
              company_id:item.in_company_id,
            }
        }else{
            row  = {
              user_id:item.send_user_id,
              company_id:item.send_company_id,
            }
        }
      if(this.PJSource==7 || this.PJSource === 21||this.PJSource === 16||this.PJSource===12||this.PJSource ===18||this.PJSource === 27||this.PJSource == 29){ // 新逻辑
        this.$GLOBALEVENT.$emit("OPENISUSERCARD", { show: true, row });
      }else{ // 旧逻辑
          this.currentId = item.in_user_id;
          this.$nextTick(() => {
            this.$GLOBALEVENT.$emit("OPENUSERCard",row);
          });
      }
    },
    // 邀请
    inviteGo(row) {
      let now_time = moment(new Date()).unix();
      if (row.end_time < now_time) {
        this.$message.warning(this.$t("chatTimeEnd"));
      } else {
        this.$refs.inviteOther.inviteIsShow(true, row);
      }
    },
  },
  computed: {
    showSelect() {
      return this.PJSource == 10;
    },
  },
};
</script>

<style scoped lang="less">
.user {
  display: flex;

  .userImg {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 12px;
    cursor: pointer;
  }

  .userInfo {
    max-width: 150px;

    .name {
      padding-bottom: 10px;
    }
  }
}

.infoDetail {
  p {
    color: #999;
    line-height: 26px;

    span {
      color: #333;
    }
  }
}

.marginL10 {
  margin-left: 10px;
}
.clickValue {
  color: #1890ff;
  cursor: pointer;
}
.tabBlock {
  padding-right: 12px;
  position: relative;
  .selectWrap {
    position: absolute;
    right: 15px;
    top: 9px;
    width: 200px;
  }
  .tabwrap {
    flex: 1;
  }
}
</style>
