<template>
  <div>
    <el-form
        :label-width="LOCALE === 'en' ? '190px' : '150px'"
        :model="form"
        :rules="rules"
        ref="form"
        :hide-required-asterisk="true"
        label-position="left"
    >
      <el-form-item :label="$t('participantslate')" prop="is_late">
        <div v-if="type === 'add'">
          <el-radio v-model="form.is_late" label="0">
            {{$t('notlatetime')}}
          </el-radio>
          <el-radio v-model="form.is_late" label="1">
            {{$t('latetime')}}
          </el-radio>
        </div>
        <div v-else>
          {{ form.is_late == 0 ? $t('notlatetime') : $t('latetime')}}
        </div>
      </el-form-item>
      <el-form-item :label="$t('CallStability')" prop="stability">
        <el-rate v-model="form.stability" class="marginT10" :disabled="type == 'view'"></el-rate>
      </el-form-item>
      <el-form-item :label="$t('Nesatisfaction')" prop="satisfaction">
        <el-rate v-model="form.satisfaction" class="marginT10" :disabled="type == 'view'"></el-rate>
      </el-form-item>
      <el-form-item :label="$t('Evaparticipants')" prop="details">
        <el-input
            type="textarea"
            :rows="4"
            class="marginT10"
            :placeholder="$t('entercontent')"
            v-model="form.details"
            :disabled="type == 'view'"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="clearfix" v-if="type == 'add'">
      <el-button :loading="savaBtnLoading" class="btn" type="primary" size="small" style="min-width:80px;margin-left:10px" @click="handleSaveBtn">{{$t('save')}}</el-button>
      <el-button size="small" style="min-width:80px;margin-left:10px" class="btn" @click="handleCancel">{{$t('cancel')}}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      defalut: 'add'
    },
    item: {
      type: Object,
      defalut: function () {
        return {
          type: 1
        }
      }
    }
  },
  name: "evaluate",
  data() {
    return {
      form: {
        is_late: null,
        stability: 0,
        satisfaction: 0,
        details: null,
      },
      rules: {
        is_late: [
          { required: true, message: this.$t('choosewhether'), trigger: 'change' }
        ],
        stability: [
          { min: 1, max: 5, type: 'number', required: true, message: this.$t('selectstability'), trigger: 'change' }
        ],
        satisfaction: [
          { min: 1, max: 5, type: 'number', required: true, message: this.$t('discusssatisfaction'), trigger: 'change' }
        ],
        details: [
          { required: true, message: this.$t('enterevaluation'), trigger: 'change' }
        ]
      },
      savaBtnLoading: false
    }
  },
  methods: {
    handleSaveBtn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSaveFn();
        } else {
          return false;
        }
      });
    },
    async handleSaveFn() {
      this.savaBtnLoading = true
      let params = Object.assign({}, this.form)
      params.chat_type = 1
      params.related_id = this.item.daily_one2one_chat_id,
      params.user_id = this.USER_ID
      // params.user_id = 52175
      if (this.item.type == 1) {
        params.in_evaluat = 1
      } else {
        params.send_evaluat = 1
      }
      let result = await this.$store.dispatch(
          "baseConsole/getmyReservedAddEvaluation",
          params
      );
      this.savaBtnLoading = false
      if (result.success) {
        this.$emit('handleSaveEvaluate')
      }
    },
    handleCancel() {
      this.$emit('handleCancelEvaluate')
    },
    async getEvaluate() {
      let params = {
        chat_type: 1,
        daily_one2one_chat_id: this.item.daily_one2one_chat_id,
      }
      if (this.item.type == 1) {
        params.other_user_id = this.item.in_user_id
      } else {
        params.other_user_id = this.item.send_user_id
      }
      let result = await this.$store.dispatch(
          "baseConsole/getmyReservedViewEvaluation",
          params
      );
      if (result.success) {
        this.form = Object.assign({}, result.data)
      }
    }
  },
 mounted() {
    if (this.type == 'view') {
      this.getEvaluate()
    }
 }
}
</script>

<style scoped lang="less">
.marginT10 {
  margin-top: 10px;
}
.btn {
  float: right;
  margin-left: 30px;
}
</style>